<template>
  <div class="userList">
    <searchBar :formItems="formItems" @search="search" />
    <div class="tableBox">
      <el-table
        :data="tableData"
        :cell-style="{ textAlign: 'center' }"
        :header-cell-style="{ textAlign: 'center' }"
        border
        style="width: 100%"
      >
        <el-table-column
          type="index"
          :index="indexMethod"
          label="序号"
          width="80"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="createTime" label="订单信息" width="300">
          <template slot-scope="scope">
            <div style="text-align: left">
              订单号码：{{ scope.row.orderNo }}
            </div>
            <div style="text-align: left">
              下单时间：{{ scope.row.createTime }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="productImg" label="商品信息" width="400">
          <template slot-scope="scope">
            <div class="spInfoBox">
              <img :src="scope.row.productImg" alt="" style="width: 100px" />
              <div class="info">
                <div>名称：{{ scope.row.productName }}</div>
                <div>规格：{{ scope.row.specification }}</div>
                <div>数量：{{ scope.row.quantity }}</div>
                <div style="text-align: left">{{ scope.row.name }}</div>
                <div style="text-align: left; margin-top: 10px">
                  <el-tag type="success" style="margin-right: 5px">{{
                    scope.row.suppliers
                  }}</el-tag>
                  <el-tag>{{ scope.row.suppliersProductId }}</el-tag>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="物流信息" width="200">
          <template slot-scope="scope">
            <div style="text-align: left">
              物流单号：{{ scope.row.flowNo || "暂无" }}
            </div>
            <div style="text-align: left">
              物流信息：{{ scope.row.flowList || "暂无" }}
            </div>
            <div style="text-align: left">
              收货人：{{ scope.row.addressName }}
            </div>
            <div style="text-align: left">
              手机号码：{{ scope.row.addressPhone }}
            </div>
            <div style="text-align: left">
              收货地址：{{ scope.row.province }}{{ scope.row.city }}{{ scope.row.county }}{{ scope.row.detail }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="productImg" label="价格">
          <template slot-scope="scope">
            <div style="width: 200px; text-align: left">
              商品价格：￥{{ scope.row.price | money }}
            </div>
            <div style="width: 200px; text-align: left">
              循环币抵扣：￥{{ scope.row.xhbUseCount | money }}
            </div>
            <div style="width: 200px; text-align: left">
              余额抵扣：￥{{ scope.row.balanceUseCount | money }}
            </div>
            <div style="width: 200px; text-align: left">
              实际支付：￥{{ scope.row.cashPayAmount | money }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="orderState" width="150" label="订单状态">
          <template slot-scope="scope">
            <span
              :class="
                scope.row.orderState == 'WAIT_PAY'
                  ? 'c_yellow'
                  : scope.row.orderState == 'WAIT_DELIVER'
                  ? 'c_yellow'
                  : scope.row.orderState == 'SHIPPED'
                  ? 'c_blue'
                  : scope.row.orderState == 'COMPLETED'
                  ? 'c_green'
                  : scope.row.orderState == 'REFUNDING'
                  ? 'c_red'
                  : scope.row.orderState == 'CLEAN'
                  ? 'c_grey'
                  : ''
              "
            >
              {{ scope.row.orderState | status }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="remarks" label="备注"></el-table-column>
        <el-table-column prop label="操作" width="160">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.orderState !== 'CLEAN'"
              type="text"
              size="small"
              @click="changeStatus(scope.row)"
              >修改状态</el-button
            >
            <el-button
              v-if="scope.row.orderState === 'WAIT_DELIVER'"
              type="text"
              size="small"
              @click="setSendGoods(scope.row)"
              >设置发货</el-button
            >
            <!-- <el-button @click="deleteData(scope.row)" type="text" size="small"
              >删除</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="fenye">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.page"
        :page-sizes="[5, 10, 20, 30, 50]"
        :page-size="page.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <UserDialog ref="dialog" @search="initPage" />
  </div>
</template>

<script>
import searchBar from "@/components/SearchBar/index";
import UserDialog from "./components/dialog.vue";
export default {
  components: { searchBar, UserDialog },
  data() {
    return {
      formItems: [
        {
          label: "",
          type: "el-input",
          fieldName: "name",
          attrs: {
            placeholder: "用户名",
          },
        },
        {
          label: "",
          type: "el-input",
          fieldName: "phone",
          attrs: {
            placeholder: "手机号码",
          },
        },
        {
          label: "",
          type: "el-input",
          fieldName: "productName",
          attrs: {
            placeholder: "商品名称",
          },
        },
        {
          label: "",
          type: "el-select",
          fieldName: "orderState",
          data: [
            {
              label: "订单状态",
              value: "",
            },
            {
              label: "待支付",
              value: "WAIT_PAY",
            },
            {
              label: "待发货",
              value: "WAIT_DELIVER",
            },
            {
              label: "待收货",
              value: "SHIPPED",
            },
            {
              label: "交易完成",
              value: "COMPLETED",
            },
            {
              label: "退款中",
              value: "REFUNDING",
            },
            {
              label: "已取消",
              value: "CLEAN",
            },
          ],
          attrs: {
            placeholder: "订单状态",
          },
        },
      ],
      page: {
        page: 1,
        size: 10,
      },
      params: {},
      total: 0,
      tableData: [],
    };
  },
  methods: {
    setSendGoods(item) {
      //设置发货
      this.$prompt("请输入快递单号", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(({ value }) => {
        if (!value) return;
        this.getAjax(
          "api/admin/order/setFlowNo",
          {
            flowNo: value,
            proOrderId: item.proOrderId,
          },
          "post"
        ).then((res) => {
          this.$message.success("修改成功");
          this.initPage();
        });
      });
    },
    indexMethod(index) {
      return index + 1 + (this.page.page - 1) * this.page.size;
    },
    handleSizeChange(size) {
      this.page.size = size;
      this.initPage();
    },
    handleCurrentChange(page) {
      this.page.page = page;
      this.initPage();
    },
    search(opt) {
      //搜索
      this.params = opt;
      this.initPage();
    },
    initPage() {
      this.getAjax(
        "api/admin/order/pageListC",
        { ...this.params, ...this.page },
        "post"
      ).then((res) => {
        this.tableData = res.data.records;
        this.total = res.data.total;
      });
    },
    add() {
      this.$refs.dialog.visible = true;
      this.$refs.dialog.initDialog();
    },
    changeStatus(item) {
      this.$refs.dialog.getInfo(item);
    },
    deleteData(item) {
      this.$confirm("确定删除吗？").then(() => {
        this.getAjax(
          "api/admin/order/del",
          { ids: [item.proOrderId] },
          "post"
        ).then((res) => {
          this.$message.success("删除成功");
          this.initPage();
        });
      });
    },
  },
  mounted() {
    this.initPage();
  },
};
</script>

<style lang="less">
.tianfulove {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #fff;
  .leftBox {
    width: 220px;
    height: 100%;
    background: #545c64;
  }
  .rightBox {
    flex: 1;
    padding: 20px;
    .spInfoBox {
      display: flex;
      .info {
        flex: 1;
        margin-left: 5px;
        text-align: left;
      }
    }
    .fenye {
      text-align: right;
      padding: 15px 10px;
    }
  }
}
</style>
